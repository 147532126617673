<template>
  <div class="monitoring">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <span class="mt-2">
        <feather-icon :icon="'BookOpenIcon'" />
        <small>
          <strong>
            <i>
              Document akan diproses untuk Daily Timeshet hingga menjadi
              Document JR 📚
            </i>
          </strong>
        </small>
      </span>
      <b-button
        id="tambah-data"
        class="button-tambah mr-1"
        :variant="'primary'"
        v-if="acl()"
        :to="{
          name: enableCreateForm,
          params: {
            previous_link: {
              name: 'pelayanan-jasa-operasional-pengajuan'
            }
          }
        }"
      >
        <span
          ><strong>
            <feather-icon icon="FilePlusIcon" />
            Buat Pengajuan Sekarang
          </strong>
        </span>
      </b-button>
    </div>
    <b-row>
      <b-col md="8" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center"></div>
        </b-form-group>
      </b-col>
      <b-col class="text-left mb-2" cols="12">
        <!-- search input -->
        <b-row>
          <b-col md="4" cols="12">
            <!-- input text with help -->
            <b-form-group>
              <label for="InputHelp">Cari Nomor JR</label>
              <small class="text-muted"> eg. <i>POB-</i></small>
              <b-form-input id="InputHelp" />
            </b-form-group>
          </b-col>
          <b-col md="4" cols="12">
            <!-- input text with help -->
            <b-form-group>
              <label for="InputHelp">Cari Vessel Identity</label>
              <small class="text-muted"> eg. <i>TB</i></small>
              <b-form-input id="InputHelp" />
            </b-form-group>
          </b-col>
          <b-col md="4" cols="12">
            <!-- input text with help -->
            <b-form-group>
              <label for="InputHelp">Cari Work Description</label>
              <small class="text-muted"> eg. <i>Muat IBC</i></small>
              <b-form-input id="InputHelp" />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <div class="custom-search d-flex justify-content-end">

        </div> -->
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <!-- :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder:
          'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      }" -->
    <vue-good-table
      ref="goodTable"
      :key="yoRender"
      mode="remote"
      class="yo-good-table"
      :total-rows="total_records"
      :columns="columns"
      :is-loading="yo_isLoading"
      :rows="rows"
      :rtl="direction"
      @on-search="onSearchTable"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: thereIsGroup
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered table-hover"
      :row-style-class="rowStyleClassFn"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <transition mode="out-in">
          <span v-if="props.column.field === 'pembuat_form'">
            <strong>
              <feather-icon :icon="'LayersIcon'" /> Diajukan Oleh
              {{ props.row.user.full_name }}
            </strong>
            <br />
            <b-badge pill class="d-block" :variant="'light-primary'">
              {{ props.row.user.perusahaan }}
            </b-badge>

            <div class="divider">
              <div class="divider-text">Document Pengajuan</div>
            </div>

            <strong>
              <feather-icon :icon="'CpuIcon'" /> Document / Manifest:
              <feather-icon :icon="'CpuIcon'" />
            </strong>
            <br />
            Status :
            <b-badge pill :variant="getStatusVariant(props.row.status)">{{
              props.row.status ?? '-'
            }}</b-badge>

            <b-button
              :variant="'primary'"
              block
              :to="{
                name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                params: {
                  id: props.row.id,
                  api: 'jops_pengajuan/',
                  previous_link: {
                    name: 'pelayanan-jasa-operasional-pengajuan'
                  }
                }
              }"
              size="sm"
            >
              <feather-icon :icon="'ClipboardIcon'" /> Preview Manifest
            </b-button>

            <!-- <b-badge pill class="d-block" :variant="'light-primary'">
              {{ BACKEND_URL }}/{{
                props.row.jops_pengajuan_uploads[0].path
              }}/{{ props.row.jops_pengajuan_uploads[0].unique_name }}
            </b-badge> -->

            <div class="divider">
              <div class="divider-text">Document Result</div>
            </div>
            <b-button
              :variant="'outline-primary'"
              block
              :to="{
                name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                params: {
                  id: props.row.id,
                  api: 'jops_pengajuan/',
                  previous_link: {
                    name: 'pelayanan-jasa-operasional-pengajuan'
                  }
                }
              }"
              size="sm"
            >
              <feather-icon :icon="'ClockIcon'" /> Check Daily Timesheet
            </b-button>
            <b-button
              :variant="'outline-primary'"
              block
              :to="{
                name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                params: {
                  id: props.row.id,
                  api: 'jops_pengajuan/',
                  previous_link: {
                    name: 'pelayanan-jasa-operasional-pengajuan'
                  }
                }
              }"
              size="sm"
            >
              <feather-icon :icon="'CreditCardIcon'" /> Check Job Requisition
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'Tanggal Pengajuan'">
            <strong
              ><i
                ><feather-icon :icon="'ClockIcon'" />
                {{ dateHumanReadAble(props.row.created_at) }}</i
              ></strong
            >
            <!-- <br/>
            <feather-icon :icon="'UserIcon'" /> {{ props.row.user.full_name }} -->
          </span>
          <span v-else-if="props.column.field === 'Uraian Kegiatan'">
            <b-list-group
              v-if="
                props.row.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs
                  .length !== 0
              "
            >
              <small
                ><strong
                  >Rencana : {{ props.row.rencana_uraian_keg }}</strong
                ></small
              >

              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
                v-for="(item, id) in props.row.jops_pengajuan_dts[0]
                  .jops_pengajuan_dts_kegs"
                :key="index"
              >
                <span>
                  <span class="mr-1">
                    <feather-icon icon="TrelloIcon" size="16" />
                  </span>
                  <strong>{{ item.uraian_kegiatan }}</strong>
                </span>
                <b-badge variant="primary" pill class="badge-round">
                  <feather-icon icon="ClockIcon" class="mr-25" />{{
                    item.time
                  }}
                  / {{ item.total_jam }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
            <span v-else>
              <b-spinner variant="primary" type="grow" small />
              <strong>Rencana : {{ props.row.rencana_uraian_keg }}</strong>
            </span>
          </span>
          <span v-else-if="props.column.field === 'Pengguna Jasa'">
            <b-badge pill :variant="'light-primary'">
              <feather-icon :icon="'UsersIcon'" />
              {{ props.row.user.perusahaan }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'Status'">
            <b-badge
              pill
              class="d-block"
              :variant="getStatusVariant(props.row.status)"
            >
              <feather-icon :icon="'CreditCardIcon'" />
              {{ props.row.status ?? '-' }}</b-badge
            >
          </span>
          <span v-else-if="props.column.field === 'daily_timesheet'">
            <b-row>
              <b-col md="6" cols="12">
                <strong><feather-icon :icon="'LayersIcon'" /> Status : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].status
                  }}</code></span
                >
              </b-col>
              <b-col md="6" cols="12">
                <strong
                  ><feather-icon :icon="'BoxIcon'" /> Pengguna Jasa :
                </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].pengguna_jasa ?? '-'
                  }}</code></span
                >
              </b-col>
            </b-row>

            <strong
              ><feather-icon :icon="'BoxIcon'" /> Total Kegiatan :
            </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs
                  .length ?? '0'
              }}</code></span
            >

            <div class="divider">
              <div class="divider-text">Peralatan</div>
            </div>

            <strong><feather-icon :icon="'LayersIcon'" /> Crane : </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_dts[0].plt_crane ?? '-'
              }}</code></span
            >
            <br />
            <strong><feather-icon :icon="'BoxIcon'" /> Forklift : </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_dts[0].plt_forklift ?? '-'
              }}</code></span
            >
            <br />
            <strong><feather-icon :icon="'BoxIcon'" /> Tronton : </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_dts[0].plt_truck ?? '-'
              }}</code></span
            >

            <div class="divider">
              <div class="divider-text">Tenaga Kerja ( PRS )</div>
            </div>

            <b-row>
              <b-col md="6" cols="12">
                <strong><feather-icon :icon="'LayersIcon'" /> Rigger : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_rigger ?? '-'
                  }}</code></span
                >
                <br />
                <strong><feather-icon :icon="'BoxIcon'" /> Tallyman : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_tallyman ?? '-'
                  }}</code></span
                >
                <br />
                <strong><feather-icon :icon="'BoxIcon'" /> HSE : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_hse ?? '-'
                  }}</code></span
                >
              </b-col>
              <b-col md="6" cols="12">
                <strong
                  ><feather-icon :icon="'LayersIcon'" /> Supervisor :
                </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_supervisor ?? '-'
                  }}</code></span
                >
                <br />
                <strong><feather-icon :icon="'BoxIcon'" /> Jettyman : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_jettyman ?? '-'
                  }}</code></span
                >
                <br />
                <strong><feather-icon :icon="'BoxIcon'" /> Security : </strong>
                <span
                  ><code>{{
                    props.row.jops_pengajuan_dts[0].tk_security ?? '-'
                  }}</code></span
                >
              </b-col>
            </b-row>
          </span>

          <span v-else-if="props.column.field === 'job_requisition'">
            <strong><feather-icon :icon="'LayersIcon'" /> Status : </strong>
            <span
              ><code>{{ props.row.jops_pengajuan_jrs[0].status }}</code></span
            >

            <div class="divider">
              <div class="divider-text">
                Nomor JR : <strong>No. PB-010724-12</strong>
              </div>
            </div>

            <strong><feather-icon :icon="'LayersIcon'" /> Client : </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_jrs[0].client ?? '-'
              }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'BoxIcon'" /> Work Description :
            </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_jrs[0].work_desc ?? '-'
              }}</code></span
            >
            <br />
            <strong><feather-icon :icon="'BoxIcon'" /> Segment : </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_jrs[0].segment ?? '-'
              }}</code></span
            >

            <div class="divider">
              <div class="divider-text">Order No :</div>
            </div>

            <strong
              ><feather-icon :icon="'BoxIcon'" /> Vessel Identity :
            </strong>
            <span
              ><code>{{
                props.row.jops_pengajuan_jrs[0].vessel_identity ?? '-'
              }}</code></span
            >
          </span>
          <span v-else-if="props.column.field === 'Details'">
            <span>
              <b-button
                :variant="'outline-primary'"
                block
                size="sm"
                v-b-modal.modal-manifest
                @click.prevent="openManifest(props.row)"
              >
                <feather-icon :icon="'EditIcon'" /> Manifest
              </b-button>
            </span>
            <span>
              <b-button
                class="mt-1"
                :variant="'outline-primary'"
                block
                size="sm"
                :to="{
                  name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                  params: {
                    id: props.row.id,
                    api: 'jops_pengajuan',
                    previous_link: {
                      name: 'pelayanan-jasa-operasional-pengajuan'
                    }
                  }
                }"
              >
                <feather-icon :icon="'PenToolIcon'" /> Operational
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'Approved'">
            <span>
              <b-button
                :variant="'outline-primary'"
                block
                size="sm"
                @click.prevent="DTLoadingID === props.row.id ? null : previewDailyTimesheet(props.row)"
              >
                <b-spinner
                  v-if="DTLoadingID === props.row.id"
                  small
                />
                <feather-icon v-else :icon="'ClockIcon'" /> Timesheet
              </b-button>
            </span>
            <span>
              <b-button
                class="mt-1"
                :variant="'outline-primary'"
                block
                size="sm"
                @click.prevent="JRLoadingID === props.row.id ? null : previewJobRequisition(props.row)"
              >
                <b-spinner
                  v-if="JRLoadingID === props.row.id"
                  small
                />
                <feather-icon v-else :icon="'CreditCardIcon'" /> Job Requisition
              </b-button>
            </span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </transition>
      </template>
    </vue-good-table>

    <!-- basic modal -->
    <b-modal
      id="modal-manifest"
      :title="`File Pengajuan ( ${dateHumanReadAble(
        detailRows?.created_at
      )} ) | Document Manifest By ${detailRows?.user?.full_name}`"
      centered
      hide-backdrop
      hide-footer
      size="lg"
    >
      <b-card-text v-if="detailRows?.jops_pengajuan_uploads?.length !== 0">
        <DropZoneUploadJOPSV1
          :key="detailRows?.id"
          :uri="BACKEND_URL"
          :update-i-d="detailRows?.id"
          accept-file="application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx"
          :max-files="10"
          re-upload-title="Re Upload Pengajuan Jasa Operasional  (Max 10 File Excel)"
          :is-have-file="isHaveFile"
          :modelName="'jops_pengajuan_upload'"
          info="Maksimal 10 Berkas"
          :disable-action="$can('Jasa OPS Viewer')"
          @callBack="dropZoneCallBack"
        />
        <h5 class="mb-1">
          NB : Mengubah file akan mengubah status Pengajuan menjadi
          <strong>"HOLD"</strong> 🕵🏻‍♀️
        </h5>
      </b-card-text>
      <div class="misc-inner p-2 p-sm-3" v-else>
        <div class="w-100 text-center">
          <h2 class="mb-1">Tidak ada file upload 🕵🏻‍♀️</h2>
          <p class="mb-2">Oops! 😖 The file is not found</p>

          <!-- image -->
          <b-img
            fluid
            src="@/assets/images/pages/error-dark.svg"
            alt="Error page"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BModal,
  VBModal
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'
import DropZoneUploadJOPSV1 from '@views/components/input/upload/DropZoneUploadJOPSV1.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CompGoodTable',
  components: {
    DropZoneUploadJOPSV1,
    BModal,
    VBModal,
    VSelect,
    BListGroup,
    BListGroupItem,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr,
    BSpinner,
    BBadge
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: {
    resolveFetchResponse: Function,
    resolveFetchPayload: Function,
    dateHumanReadAble: Function,
    columns: Array,
    thereIsGroup: Boolean,
    enableCreateForm: String,
    enableViewForm: String
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotificationRiver } =
      useAppConfig()
    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  data() {
    return {
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */
      DTLoading: false,
      DTLoadingID: undefined,
      JRLoading: false,
      JRLoadingID: undefined,

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      detailRows: [],
      selectedRows: [],
      isHaveFile: [],
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          type: 'DESC',
          field: 'created_at'
        },
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    perlatanData() {
      return (item) => {
        let our_peralatan = []
        item.forEach(async (element) => {
          const data = `${element.peralatan} ( ${element.operator_driver} )`
          our_peralatan.push(data)
        })
        console.log('our_peralatan', our_peralatan)
        return our_peralatan
      }
    },
    acl() {
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')
      const oprCanModified = this.$can('Jasa OPS Pengguna', '')

      const isViewer = this.$store.state.app.roleName == 'viewer'
      const isJettyMan = this.$store.state.app.roleName == 'jetty man'
      let acl = false
      if (hostCanModified || salCanModified || oprCanModified) {
        acl = true
      }
      if (isViewer || isJettyMan) {
        acl = false
      }
      return () => acl
    },
    rowStyleClassFn() {
      return (row) => {
        // console.log(row.form_tbbm_id)
        return 'custom-transition'
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formStatusVariant() {
      return (any) => this.$variantColumnFormStatus[any]
    },
    verifiedVariant() {
      return (any) => this.$variantVerified[any]
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotificationRiver() {
      if (!this.serverParams.searchTerm) {
        console.log(
          'anIncomingNotificationRiver ::',
          this.anIncomingNotificationRiver
        )
        let high_light_data =
          this.anIncomingNotificationRiver.data[
            `gcm.notification.screen_re_fetch`
          ]
        let will_be_refresh = ['form_tug_boat', 'form_chain_tbbm']
        let go_refresh = will_be_refresh.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh::', go_refresh)

        if (go_refresh) {
          this.fetchData(true, '')
        }
      }
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
    }
  },
  mounted() {
    // document.body.style.zoom = '100%'
    const isBack = !!this.$router.currentRoute.params?.reFetch
    console.log('mounted', isBack)
    if (isBack) {
      // this.fetchData(true, '')
    }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    // this.fetchData(true, '')
    console.log('this.$refs.goodTable::', this.$refs.goodTable)
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    async previewJobRequisition(data) {
      // signature all JR
      this.$swal({
        title: 'Are you sure?',
        text: `Generate JOB Requisition`,
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: `Yes, Generate it!`,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.JRLoadingID = data.id
          const uriAPI = `jops_pengajuan/generate/job-requisition/${data.id}`
          await useJwt.http
            .post(uriAPI, '')
            .then((response) => {
              const uri = `${this.BACKEND_URL}${response.data.job_requisition.link}`
              window.open(uri)
              this.JRLoadingID = undefined
            })
            .catch((error) => {
              this.JRLoadingID = undefined
              // Ensure error.response exists
              const errorMessage = error.response?.data?.message || 'Unknown error';
              console.log('An error:', errorMessage);
              this.$swal({
                title: 'OOPS',
                html: `Something wrong on Export File.<br/><code>${errorMessage}</code>`,
                icon: 'error',
                showCancelButton: false
              })
            })
        }
      })
    },
    async previewDailyTimesheet(data) {
      // signature all DT
      this.$swal({
        title: 'Are you sure?',
        text: `Generate Daily Timesheet`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, Generate it!`,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.DTLoadingID = data.id
          const uriAPI = `jops_pengajuan/generate/daily-timesheet/${data.id}`
          await useJwt.http
            .post(uriAPI, '')
            .then((response) => {
              const uri = `${this.BACKEND_URL}${response.data.daily_timesheet.link}`
              window.open(uri)
              this.DTLoadingID = undefined
            })
            .catch((error) => {
              this.DTLoadingID = undefined
              this.$swal({
                title: 'OOPS',
                html: `Something wrong on Export File ${error}`,
                icon: 'error',
                showCancelButton: false
              })
            })
        }
      })
    },
    aclBtnDailyTimesheet(data) {
      //
    },
    aclBtnJR(data) {
      this.$swal({
        position: 'top-end',
        icon: 'info',
        title: 'Daily Timesheet Belum diTerbitkan',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    // if(this.$can('manage', 'all')){
    // this.previewDailyTimesheet()
    //   return
    // }
    // if (
    //   this.$store.state.app.roleName === 'viewer' ||
    //   this.$store.state.app.roleName === 'jetty man' ||
    //   this.$store.state.app.roleName === 'security' ||
    //   this.isJasaOPSPengguna()
    // ) {
    //   /* return as disable */
    //   return false
    // } else {
    //   /* return as enable */
    //   return true
    // }
    // },
    // isJasaOPSPengguna() {
    //   if(this.$can('manage', 'all')){
    //     return true
    //   }
    //   if(this.$can('Jasa OPS Pengguna', '')){
    //     /* return as enable */
    //     return true
    //   } else {
    //     /* return as disable */
    //     return false
    //   }
    // },
    moment,
    async dropZoneCallBack(val, files) {
      if (val === 'success') {
        await useJwt.http
          .put(`jops_pengajuan/${this.detailRows.id}`, '')
          .then(() => {
            // Find the index of the item with matching `id`
            const index = this.rows.findIndex(
              (row) => row.id === this.detailRows.id
            )

            // If found, update the `status`
            if (index !== -1) {
              this.$set(this.rows, index, {
                ...this.rows[index],
                status: 'HOLD',
                jops_pengajuan_uploads: files
              })
            }
            this.$alert({
              title: `Berhasil update Document Manifest`,
              variant: 'success',
              icon: 'CheckCircleIcon'
            })
          })
      }
    },
    openManifest(data) {
      const { jops_pengajuan_uploads, created_at, user, status, id } = data
      this.detailRows = {
        created_at,
        user,
        status,
        id
      }
      this.isHaveFile = []

      if (jops_pengajuan_uploads.length !== 0) {
        this.detailRows = {
          created_at,
          user,
          status,
          id,
          jops_pengajuan_uploads
        }
        jops_pengajuan_uploads.forEach((item) => {
          this.isHaveFile.push({
            id: `${item.id}`,
            jops_pengajuan_id: `${item.jops_pengajuan_id}`,
            original_name: `${item.original_name}`,
            size: `${item.size}`,
            download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
          })
        })
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'IN-PROGRESS':
          return 'warning' // Yellow
        case 'COMPLETED':
          return 'success' // Green
        case 'PENDING':
          return 'danger' // Red
        case 'PLAN':
          return 'primary' // Blue
        case 'DAILY':
          return 'warning' // Light Blue
        case 'JR':
          return 'success' // Black/Gray
        default:
          return 'secondary' // Gray
      }
    },
    formatNumber(number) {
      if (number != null) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      } else {
        return 0
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(`onSortChange : ${JSON.stringify(params)}`)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    // #fetchData
    async fetchData() {
      this.isTimerStart()
      const payload = this.resolveFetchPayload()
      const uri = payload.API_SAL

      this.serverParams = {
        ...this.serverParams
        // ...originalServerParams
      }

      await useJwt.http
        .get(`${uri}`, {
          params: { ...this.serverParams }
        })
        .then((res) => {
          // console.log('an error [res.data]', res.data);
          const { total_items } = res.data
          this.total_records = total_items
          const get_rows = this.resolveFetchResponse(res).map((data) => ({
            ...data
          }))
          console.log('an error [total_items]', total_items)
          console.log('an error [res.data]', res.data)

          this.rows = get_rows
          this.isTimerDone()
        })
        .catch((error) => {
          console.log('an error [get]', error)
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;

#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;

            .vgt-global-search__input {
              border: none !important;

              .input__icon {
                font-weight: 700 !important;
              }

              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }

          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;

            thead {
              th {
                border: $color-yo-border !important;
              }

              /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }

            tbody {
              tr.custom-transition {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-active,
              tr.custom-transition-leave-active {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-enter-from,
              tr.custom-transition-leave-to {
                opacity: 0;
              }

              th {
                border: $color-yo-border !important;
              }

              /* left checkbox */
              td {
                border: $color-yo-border !important;
              }

              /* right checkbox */
            }
          }

          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }

            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
