var render = function () {
  var _vm$detailRows, _vm$detailRows2, _vm$detailRows2$user, _vm$detailRows3, _vm$detailRows3$jops_, _vm$detailRows4, _vm$detailRows5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-between"
  }, [_c('span', {
    staticClass: "mt-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": 'BookOpenIcon'
    }
  }), _vm._m(0)], 1), _vm.acl() ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'primary',
      "to": {
        name: _vm.enableCreateForm,
        params: {
          previous_link: {
            name: 'pelayanan-jasa-operasional-pengajuan'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Buat Pengajuan Sekarang ")], 1)])]) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-left mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "InputHelp"
    }
  }, [_vm._v("Cari Nomor JR")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" eg. "), _c('i', [_vm._v("POB-")])]), _c('b-form-input', {
    attrs: {
      "id": "InputHelp"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "InputHelp"
    }
  }, [_vm._v("Cari Vessel Identity")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" eg. "), _c('i', [_vm._v("TB")])]), _c('b-form-input', {
    attrs: {
      "id": "InputHelp"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "InputHelp"
    }
  }, [_vm._v("Cari Work Description")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" eg. "), _c('i', [_vm._v("Muat IBC")])]), _c('b-form-input', {
    attrs: {
      "id": "InputHelp"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    ref: "goodTable",
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "is-loading": _vm.yo_isLoading,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "row-style-class": _vm.rowStyleClassFn,
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row$status, _props$row$status2, _props$row$jops_penga, _props$row$jops_penga2, _props$row$jops_penga3, _props$row$jops_penga4, _props$row$jops_penga5, _props$row$jops_penga6, _props$row$jops_penga7, _props$row$jops_penga8, _props$row$jops_penga9, _props$row$jops_penga10, _props$row$jops_penga11, _props$row$jops_penga12, _props$row$jops_penga13, _props$row$jops_penga14, _props$row$jops_penga15;
        return [_c('transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [props.column.field === 'pembuat_form' ? _c('span', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Diajukan Oleh " + _vm._s(props.row.user.full_name) + " ")], 1), _c('br'), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_vm._v(" " + _vm._s(props.row.user.perusahaan) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Document Pengajuan")])]), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'CpuIcon'
          }
        }), _vm._v(" Document / Manifest: "), _c('feather-icon', {
          attrs: {
            "icon": 'CpuIcon'
          }
        })], 1), _c('br'), _vm._v(" Status : "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.getStatusVariant(props.row.status)
          }
        }, [_vm._v(_vm._s((_props$row$status = props.row.status) !== null && _props$row$status !== void 0 ? _props$row$status : '-'))]), _c('b-button', {
          attrs: {
            "variant": 'primary',
            "block": "",
            "to": {
              name: 'pelayanan-jasa-operasional-pengajuan-wizard',
              params: {
                id: props.row.id,
                api: 'jops_pengajuan/',
                previous_link: {
                  name: 'pelayanan-jasa-operasional-pengajuan'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'ClipboardIcon'
          }
        }), _vm._v(" Preview Manifest ")], 1), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Document Result")])]), _c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "to": {
              name: 'pelayanan-jasa-operasional-pengajuan-wizard',
              params: {
                id: props.row.id,
                api: 'jops_pengajuan/',
                previous_link: {
                  name: 'pelayanan-jasa-operasional-pengajuan'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'ClockIcon'
          }
        }), _vm._v(" Check Daily Timesheet ")], 1), _c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "to": {
              name: 'pelayanan-jasa-operasional-pengajuan-wizard',
              params: {
                id: props.row.id,
                api: 'jops_pengajuan/',
                previous_link: {
                  name: 'pelayanan-jasa-operasional-pengajuan'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'CreditCardIcon'
          }
        }), _vm._v(" Check Job Requisition ")], 1)], 1) : props.column.field === 'Tanggal Pengajuan' ? _c('span', [_c('strong', [_c('i', [_c('feather-icon', {
          attrs: {
            "icon": 'ClockIcon'
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.created_at)))], 1)])]) : props.column.field === 'Uraian Kegiatan' ? _c('span', [props.row.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs.length !== 0 ? _c('b-list-group', [_c('small', [_c('strong', [_vm._v("Rencana : " + _vm._s(props.row.rencana_uraian_keg))])]), _vm._l(props.row.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs, function (item, id) {
          return _c('b-list-group-item', {
            key: _vm.index,
            staticClass: "d-flex justify-content-between align-items-center"
          }, [_c('span', [_c('span', {
            staticClass: "mr-1"
          }, [_c('feather-icon', {
            attrs: {
              "icon": "TrelloIcon",
              "size": "16"
            }
          })], 1), _c('strong', [_vm._v(_vm._s(item.uraian_kegiatan))])]), _c('b-badge', {
            staticClass: "badge-round",
            attrs: {
              "variant": "primary",
              "pill": ""
            }
          }, [_c('feather-icon', {
            staticClass: "mr-25",
            attrs: {
              "icon": "ClockIcon"
            }
          }), _vm._v(_vm._s(item.time) + " / " + _vm._s(item.total_jam) + " ")], 1)], 1);
        })], 2) : _c('span', [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "type": "grow",
            "small": ""
          }
        }), _c('strong', [_vm._v("Rencana : " + _vm._s(props.row.rencana_uraian_keg))])], 1)], 1) : props.column.field === 'Pengguna Jasa' ? _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": 'light-primary'
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'UsersIcon'
          }
        }), _vm._v(" " + _vm._s(props.row.user.perusahaan) + " ")], 1)], 1) : props.column.field === 'Status' ? _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": _vm.getStatusVariant(props.row.status)
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'CreditCardIcon'
          }
        }), _vm._v(" " + _vm._s((_props$row$status2 = props.row.status) !== null && _props$row$status2 !== void 0 ? _props$row$status2 : '-'))], 1)], 1) : props.column.field === 'daily_timesheet' ? _c('span', [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Status : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.jops_pengajuan_dts[0].status))])])]), _c('b-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Pengguna Jasa : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga = props.row.jops_pengajuan_dts[0].pengguna_jasa) !== null && _props$row$jops_penga !== void 0 ? _props$row$jops_penga : '-'))])])])], 1), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Total Kegiatan : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga2 = props.row.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs.length) !== null && _props$row$jops_penga2 !== void 0 ? _props$row$jops_penga2 : '0'))])]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Peralatan")])]), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Crane : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga3 = props.row.jops_pengajuan_dts[0].plt_crane) !== null && _props$row$jops_penga3 !== void 0 ? _props$row$jops_penga3 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Forklift : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga4 = props.row.jops_pengajuan_dts[0].plt_forklift) !== null && _props$row$jops_penga4 !== void 0 ? _props$row$jops_penga4 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Tronton : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga5 = props.row.jops_pengajuan_dts[0].plt_truck) !== null && _props$row$jops_penga5 !== void 0 ? _props$row$jops_penga5 : '-'))])]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Tenaga Kerja ( PRS )")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Rigger : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga6 = props.row.jops_pengajuan_dts[0].tk_rigger) !== null && _props$row$jops_penga6 !== void 0 ? _props$row$jops_penga6 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Tallyman : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga7 = props.row.jops_pengajuan_dts[0].tk_tallyman) !== null && _props$row$jops_penga7 !== void 0 ? _props$row$jops_penga7 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" HSE : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga8 = props.row.jops_pengajuan_dts[0].tk_hse) !== null && _props$row$jops_penga8 !== void 0 ? _props$row$jops_penga8 : '-'))])])]), _c('b-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Supervisor : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga9 = props.row.jops_pengajuan_dts[0].tk_supervisor) !== null && _props$row$jops_penga9 !== void 0 ? _props$row$jops_penga9 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Jettyman : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga10 = props.row.jops_pengajuan_dts[0].tk_jettyman) !== null && _props$row$jops_penga10 !== void 0 ? _props$row$jops_penga10 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Security : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga11 = props.row.jops_pengajuan_dts[0].tk_security) !== null && _props$row$jops_penga11 !== void 0 ? _props$row$jops_penga11 : '-'))])])])], 1)], 1) : props.column.field === 'job_requisition' ? _c('span', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Status : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.jops_pengajuan_jrs[0].status))])]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v(" Nomor JR : "), _c('strong', [_vm._v("No. PB-010724-12")])])]), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Client : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga12 = props.row.jops_pengajuan_jrs[0].client) !== null && _props$row$jops_penga12 !== void 0 ? _props$row$jops_penga12 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Work Description : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga13 = props.row.jops_pengajuan_jrs[0].work_desc) !== null && _props$row$jops_penga13 !== void 0 ? _props$row$jops_penga13 : '-'))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Segment : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga14 = props.row.jops_pengajuan_jrs[0].segment) !== null && _props$row$jops_penga14 !== void 0 ? _props$row$jops_penga14 : '-'))])]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Order No :")])]), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Vessel Identity : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s((_props$row$jops_penga15 = props.row.jops_pengajuan_jrs[0].vessel_identity) !== null && _props$row$jops_penga15 !== void 0 ? _props$row$jops_penga15 : '-'))])])]) : props.column.field === 'Details' ? _c('span', [_c('span', [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-manifest",
            modifiers: {
              "modal-manifest": true
            }
          }],
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.openManifest(props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'EditIcon'
          }
        }), _vm._v(" Manifest ")], 1)], 1), _c('span', [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "size": "sm",
            "to": {
              name: 'pelayanan-jasa-operasional-pengajuan-wizard',
              params: {
                id: props.row.id,
                api: 'jops_pengajuan',
                previous_link: {
                  name: 'pelayanan-jasa-operasional-pengajuan'
                }
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": 'PenToolIcon'
          }
        }), _vm._v(" Operational ")], 1)], 1)]) : props.column.field === 'Approved' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              _vm.DTLoadingID === props.row.id ? null : _vm.previewDailyTimesheet(props.row);
            }
          }
        }, [_vm.DTLoadingID === props.row.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": 'ClockIcon'
          }
        }), _vm._v(" Timesheet ")], 1)], 1), _c('span', [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              _vm.JRLoadingID === props.row.id ? null : _vm.previewJobRequisition(props.row);
            }
          }
        }, [_vm.JRLoadingID === props.row.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": 'CreditCardIcon'
          }
        }), _vm._v(" Job Requisition ")], 1)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-manifest",
      "title": "File Pengajuan ( ".concat(_vm.dateHumanReadAble((_vm$detailRows = _vm.detailRows) === null || _vm$detailRows === void 0 ? void 0 : _vm$detailRows.created_at), " ) | Document Manifest By ").concat((_vm$detailRows2 = _vm.detailRows) === null || _vm$detailRows2 === void 0 ? void 0 : (_vm$detailRows2$user = _vm$detailRows2.user) === null || _vm$detailRows2$user === void 0 ? void 0 : _vm$detailRows2$user.full_name),
      "centered": "",
      "hide-backdrop": "",
      "hide-footer": "",
      "size": "lg"
    }
  }, [((_vm$detailRows3 = _vm.detailRows) === null || _vm$detailRows3 === void 0 ? void 0 : (_vm$detailRows3$jops_ = _vm$detailRows3.jops_pengajuan_uploads) === null || _vm$detailRows3$jops_ === void 0 ? void 0 : _vm$detailRows3$jops_.length) !== 0 ? _c('b-card-text', [_c('DropZoneUploadJOPSV1', {
    key: (_vm$detailRows4 = _vm.detailRows) === null || _vm$detailRows4 === void 0 ? void 0 : _vm$detailRows4.id,
    attrs: {
      "uri": _vm.BACKEND_URL,
      "update-i-d": (_vm$detailRows5 = _vm.detailRows) === null || _vm$detailRows5 === void 0 ? void 0 : _vm$detailRows5.id,
      "accept-file": "application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx",
      "max-files": 10,
      "re-upload-title": "Re Upload Pengajuan Jasa Operasional  (Max 10 File Excel)",
      "is-have-file": _vm.isHaveFile,
      "modelName": 'jops_pengajuan_upload',
      "info": "Maksimal 10 Berkas",
      "disable-action": _vm.$can('Jasa OPS Viewer')
    },
    on: {
      "callBack": _vm.dropZoneCallBack
    }
  }), _c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" NB : Mengubah file akan mengubah status Pengajuan menjadi "), _c('strong', [_vm._v("\"HOLD\"")]), _vm._v(" 🕵🏻‍♀️ ")])], 1) : _c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v("Tidak ada file upload 🕵🏻‍♀️")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Oops! 😖 The file is not found")]), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": require("@/assets/images/pages/error-dark.svg"),
      "alt": "Error page"
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', [_c('strong', [_c('i', [_vm._v(" Document akan diproses untuk Daily Timeshet hingga menjadi Document JR 📚 ")])])]);
}]

export { render, staticRenderFns }