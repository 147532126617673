<!-- KHUSUS OPERASIONAL -->
<template>
  <Table
    ref-specify="Table"
    :columns="columns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    enable-create-form="pelayanan-jasa-operasional-pengajuan-create-wizard"
  />
</template>

<script>
import moment from 'moment'
import Table from './_table.vue'

export default {
  name: 'YoJasaOperasionalPengajuan',
  components: {
    Table
  },
  data() {
    return {
      columns: [
        {
          label: 'Tanggal Pengajuan',
          field: 'Tanggal Pengajuan',
          width: '250px'
        },
        {
          label: 'Rencana / Uraian Kegiatan',
          field: 'Uraian Kegiatan',
          width: '570px'
        },
        {
          label: 'Pengguna Jasa',
          field: 'Pengguna Jasa',
          width: '250px'
        },
        {
          label: 'Status',
          field: 'Status',
          width: '150px'
        },
        {
          label: 'Details',
          field: 'Details',
          width: '170px'
        },
        {
          label: 'Approved',
          field: 'Approved',
          width: '180px'
        },
      ]
    }
  },
  created() {
    // this.$root.$moment.locale('id')
  },
  methods: {
    moment,
    resolveFetchPayload() {
      return {
        API_SAL: 'jops_pengajuan',
        serverParams: {
          sort: {
            type: 'DESC',
            field: 'created_at'
          },
          perPage: 7 // override
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    resolveFetchResponse(response) {
      console.log('resolveFetchResponse')
      return response.data.jops_pengajuans
    }
  }
}
</script>
