<template>
  <div>
    <h1>
      <feather-icon size="30" :icon="'BookOpenIcon'" />
       Pengajuan Pelayanan Jasa Operasional
      <feather-icon size="30" :icon="'BookOpenIcon'" />
    </h1>

    <BCardActions
      title=""
      sub-title=""
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >

      <Table />
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Table from './table.vue'

export default {
  components: {
    BCardActions,
    Table
  },
  data(){
    return {
    }
  },
  mounted() {
    document.body.style.zoom = '80%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>
